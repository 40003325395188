<template>
  <div>
    <!-- UniverseTheGame/images/picBE93DF331D41246586D5CFDD00D764F5.png -->
    <banner-module
      :img="require('@/assets/the-game/membership-banner.png')"
    ></banner-module>
    <text-module title="Why upgrade to a membership?">
      <div style="margin: 0px 10px 10px">
        <router-link
          style="float: right; margin-left: 20px"
          to="/parents/subscription"
          ><!--UniverseTheGame/images/picB4904E7EBC92E67E1923C9F936873B0D.png --><img
            src="@/assets/the-game/membership-ad.png"
          />
        </router-link>
        <p>
          With a paid Membership your entire game experience increases giving
          you access to all the worlds, battle and action that LEGO Universe has
          to offer. Plus you get access to our regular game updates and cool
          in-game rewards.
        </p>
        <b>How do I become a member?</b>
        <p>
          You can at all times convert your Free to Play account into a
          membership simply by upgrading either in-game or via My Account on
          LEGOuniverse.com.
        </p>
        <p>
          Please note that there is a monthly payment for your membership. You
          can pay for 1 month or for several at a time. Whereas the Free to Play
          mode offers selected in-game features, a paid membership account
          offers everything that LEGO Universe has to offer.
        </p>
        <span style="font-weight: bold; font-size: 16px; color: #fff"
          >See the difference between Free to Play and Membership here:</span
        >
      </div>

      <div class="clear"></div>
      <div id="MembershipInfo">
        <div class="membership-info">
          <div class="title">
            <b></b><i></i>
            <span></span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> FREE TO PLAY </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> MEMBERSHIP </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> Customizable Heroes </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 1 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 4 </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> LEGO Universe Worlds </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 2 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 8 </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> Mini Games and Activities </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 2 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 9 </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> Minifigure Faction Gear </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 1 Kit (Borrowed) </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 36 kits </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> LEGO Bricks types </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 250 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> Almost 2000 </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> Build Properties </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 1 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 6 </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> LEGO Universe Coins </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> Max. 10,000 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> Unlimited </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> Friends </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 5 </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 50 </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> Best Friends </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> - </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span>
              <!-- http://cache.lego.com/universe/images/game/tick.png -->
              <img src="@/assets/images/game/tick.png" width="15" height="15" />
            </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> Full Chat </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> - </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span>
              <!-- http://cache.lego.com/universe/images/game/tick.png -->
              <img src="@/assets/images/game/tick.png" width="15" height="15" />
            </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> Missions and Achievements </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 100+ </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 1200+ </span>
          </div>
        </div>

        <div class="membership-info even">
          <div class="title">
            <b></b><i></i>
            <span> Pets </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> - </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 20+ </span>
          </div>
        </div>

        <div class="membership-info odd">
          <div class="title">
            <b></b><i></i>
            <span> Items </span>
          </div>
          <div class="freetoplay">
            <b></b><i></i>
            <span> 1000+ </span>
          </div>
          <div class="membership">
            <b></b><i></i>
            <span> 3500+ </span>
          </div>
        </div>
      </div>
    </text-module>
  </div>
</template>

<style scoped lang="scss">
  #MembershipInfo {
    display: table;

    .membership-info {
      display: table-row;

      .title, .freetoplay, .membership {
        display: table-cell;
        padding: 5px;
      }

      .title {
        font-weight: bold;
      }
    }
  }
</style>